import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

const DEFAULT_VALUES: any = {
};

interface CheckoutFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
}

export default function CouponConfigurationForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.couponConfiguration')}>
        <TextField
          control={control}
          name="couponConfiguration.serviceCredentialID"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.couponConfiguration.serviceCredentialID')}
          helperText={t('checkoutConfig.helperText.couponConfiguration.serviceCredentialID')}
        />

        <TextField
          control={control}
          name="couponConfiguration.customRedemptionType"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.couponConfiguration.customRedemptionType')}
          helperText={t('checkoutConfig.helperText.couponConfiguration.customRedemptionType')}
        />

        <CheckboxField
          control={control}
          name="couponConfiguration.useCouponsService"
          disabled={readonly}
          label={`${t('checkoutConfig.label.couponConfiguration.useCouponsService')} (${t('checkoutConfig.helperText.couponConfiguration.useCouponsService')})`}
        />

        <TextField
          control={control}
          name="couponConfiguration.couponLookupURL"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.couponConfiguration.couponLookupURL')}
          helperText={t('checkoutConfig.helperText.couponConfiguration.couponLookupURL')}
        />

      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
